// initializeTooltips.js
import $ from 'jquery';
import 'jquery-ui/ui/widgets/tooltip';  // This imports the tooltip functionality

const initializeTooltips = () => {
  $(document).tooltip({
    items: "[data-toggle='tooltip']", // Elements that require tooltips
    content: function() {
      var element = $(this);
      if (element.is("[data-toggle='tooltip']")) {
        return element.attr("data-original-title");
      }
    }
  });
};

export default initializeTooltips;
