/* eslint-disable */
import LazySizes from 'lazysizes'
import Unveilhooks from 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
/* eslint-enable */

//import $ from 'jquery'
//import ionRangeSlider from 'ion-rangeslider'

import { onDocumentReady } from './utils'
import initializeTooltips from './components/tooltip'
import initializeFAQ from './components/faq'  // Import the new FAQ functionality

// Components
import Menu from './components/menu'
//import Calculator from './components/calculator'

onDocumentReady(() => {
	new Menu()
	//new Calculator()

	initializeTooltips();
	initializeFAQ();  // Initialize the FAQ functionality


	// This is an example of how to do code splitting. The JS in this
	// referenced file will only be loaded on that page. Good for
	// when you have a large amount of JS only needed in one place
	//
	//  if (document.querySelector('#js-process')) {
	//    import(/* webpackChunkName: "process" */ './pages/process')
	//      .then(module => {
	//        const Process = module.default;
	//        this.process = new Process();
	//      });
	//  }
})
