// faq.js
const initializeFAQ = () => {
    const faqItems = document.querySelectorAll('.accordion-panel-title');
  
    faqItems.forEach(item => {
      item.addEventListener('click', function() {
        // Toggle the 'collapsed' class on the clicked item
        this.classList.toggle('collapsed');
  
        // Get the target panel
        const targetId = this.getAttribute('data-target');
        const targetPanel = document.querySelector(targetId);
  
        // Toggle the 'show' class on the target panel
        if (targetPanel) {
          targetPanel.classList.toggle('show');
        }
  
        // Toggle aria-expanded attribute
        const isExpanded = this.getAttribute('aria-expanded') === 'true';
        this.setAttribute('aria-expanded', !isExpanded);
      });
    });
  };
  
  export default initializeFAQ;
  