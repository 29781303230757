class Menu {
  constructor() {
    this.dropdownToggles = document.querySelectorAll('.dropdown-toggle');
    this.navbarToggler = document.querySelector('.navbar-toggler');
    this.navbarMenu = document.getElementById('navbarSupportedContent');
    this.initialize();
  }

  initialize() {
    if (this.dropdownToggles.length > 0) {
      this.setupDropdowns();
    }
    if (this.navbarToggler && this.navbarMenu) {
      this.setupNavbarToggler();
    }
  }

  setupDropdowns() {
    this.dropdownToggles.forEach(dropdownToggle => {
      dropdownToggle.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        const dropdownMenu = dropdownToggle.nextElementSibling;
        const dropdownLi = dropdownToggle.parentElement;

        if (dropdownMenu && dropdownLi) {
          if (dropdownMenu.style.display === 'block') {
            dropdownMenu.style.display = 'none';
            dropdownLi.classList.remove('show');
          } else {
            this.closeAllDropdowns(dropdownMenu);
            dropdownMenu.style.display = 'block';
            dropdownLi.classList.add('show');
          }
        }
      });
    });

    document.addEventListener('click', () => {
      this.closeAllDropdowns(null);
    });
  }

  closeAllDropdowns(exceptMenu) {
    this.dropdownToggles.forEach(toggle => {
      const menu = toggle.nextElementSibling;
      const li = toggle.parentElement;
      if (menu && menu !== exceptMenu && li) {
        menu.style.display = 'none';
        li.classList.remove('show');
      }
    });
  }

  setupNavbarToggler() {
    this.navbarToggler.addEventListener('click', () => {
      const expanded = this.navbarToggler.getAttribute('aria-expanded') === 'true' || false;
      this.navbarMenu.style.display = expanded ? 'none' : 'block';
      this.navbarToggler.setAttribute('aria-expanded', !expanded);
    });
  }
}

export default Menu;